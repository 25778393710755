import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import TopMenu from "./components/TopMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css"
import "./App.min.css";
import PublicRoute from "./routes/publicRoutes";
import PrivateRoute from "./routes/privateRoutes";
import StripeCheckoutPage from "./views/pages/payment/stripe/StripeCheckoutPage";
import StripeReturnPage from "./views/pages/payment/stripe/StripeReturnPage";
import PayEXReturnPage from "./views/pages/payment/payx/PayEXReturnPage";
import Trainers from "./views/Trainers";
import Publications from "./views/Publications";
import Services from "./views/Services";
import About from "./views/About";
import Contact from "./views/Contact";
import OrderDetails from "./components/profile/OrderDetails";
import ScrollToTop from "./components/ScrollToTop";
import ResetPassword from "./views/pages/ResetPassword";
import PasswordLink from "./components/account/PasswordLink";
import StripeCancelPage from "./views/pages/payment/stripe/StriperCancelPage";
import Shipping from "./views/Shipping";
import { StripeSuccessPage } from "./views/pages/payment/stripe/StripeSuccessPage";
import { StripeFailedPage } from "./views/pages/payment/stripe/StripeFailedPage";

const HomeView = lazy(() => import("./views/Home"));
const SignInView = lazy(() => import("./views/account/SignIn"));
const SignUpView = lazy(() => import("./views/account/SignUp"));
const ForgotPasswordView = lazy(() => import("./views/account/ForgotPassword"));
const OrdersView = lazy(() => import("./views/account/Orders"));
const WishlistView = lazy(() => import("./views/account/Wishlist"));
const NotificationView = lazy(() => import("./views/account/Notification"));
const MyProfileView = lazy(() => import("./views/account/MyProfile"));
const ProductListView = lazy(() => import("./views/product/List"));
const ProductDetailView = lazy(() => import("./views/product/Detail"));
const StarZoneView = lazy(() => import("./views/product/StarZone"));
const CartView = lazy(() => import("./views/cart/Cart"));
const CheckoutView = lazy(() => import("./views/cart/Checkout"));
const InvoiceView = lazy(() => import("./views/cart/Invoice"));
const NotFoundView = lazy(() => import("./views/pages/404"));
const InternalServerErrorView = lazy(() => import("./views/pages/500"));
const ContactUsView = lazy(() => import("./views/pages/ContactUs"));
const SupportView = lazy(() => import("./views/pages/Support"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Disclaimer = lazy(() => import("./views/Disclaimer"));
const Privacy = lazy(() => import("./views/Privacy"));
const Layout = lazy(() => import("./layout/Layout"))
function App() {
  return (
    <BrowserRouter>
      <Suspense
      >
        {/* <React.Fragment> */}
        {/* <TopMenu /> */}
        <ScrollToTop />

        <Routes >
          <Route exact path="/" element={<HomeView />} />
          <Route exact path="/about" element={<Layout><About /></Layout>} />
          <Route exact path="/services" element={<Layout><Services /></Layout>} />
          <Route exact path="/publications" element={<Layout><Publications /></Layout>} />
          <Route exact path="/trainers" element={<Layout><Trainers /></Layout>} />
          <Route exact path="/contact" element={<Layout><Contact /></Layout>} />
          <Route exact path="/success" element={<StripeSuccessPage />} />
          <Route exact path="/failed" element={<StripeFailedPage/>} />

          <Route exact path="/account/signin" element={<Layout><PublicRoute><SignInView /></PublicRoute></Layout>} />
          <Route exact path="/account/signup" element={<Layout><PublicRoute><SignUpView /></PublicRoute></Layout>} />
          <Route exact path="/reset-password" element={<Layout><PublicRoute><ResetPassword /></PublicRoute></Layout>} />
          <Route
            exact
            path="/account/forgotpassword"
            element={<Layout><PublicRoute><ForgotPasswordView /></PublicRoute></Layout>}
          />
          <Route exact path="/password-link" element={<Layout><PasswordLink /></Layout>} />
          <Route exact path="/account/profile" element={<Layout><PrivateRoute><MyProfileView /></PrivateRoute></Layout>} />
          <Route exact path="/account/orders" element={<Layout><PrivateRoute><OrdersView /></PrivateRoute></Layout>} />
          <Route exact path="/account/wishlist" element={<Layout><WishlistView /></Layout>} />
          <Route exact path="/profile" element={<Layout><PrivateRoute><Profile /></PrivateRoute></Layout>} />
          <Route
            exact
            path="/account/notification"
            element={<Layout><NotificationView /></Layout>}
          />
          <Route exact path="/category" element={<Layout><ProductListView /></Layout>} />

          <Route exact path="/product/detail" element={<Layout><ProductDetailView /></Layout>} />
          <Route exact path="/star/zone" element={<Layout><StarZoneView /></Layout>} />
          <Route exact path="/cart" element={<Layout><CartView /></Layout>} />
          <Route exact path="/checkout" element={<Layout><CheckoutView /></Layout>} />
          <Route exact path="/invoice" element={<Layout><InvoiceView /></Layout>} />
          <Route exact path="/contact-us" element={<Layout><ContactUsView /></Layout>} />

          <Route exact path="/support" element={<Layout><SupportView /></Layout>} />
          <Route exact path="/stripecheckout" element={<StripeCheckoutPage />} />

          <Route exact path="/500" element={<InternalServerErrorView />} />
          <Route path="/st/return" element={<StripeReturnPage />} />
          <Route path="/px/return" element={<PayEXReturnPage />} />
          <Route exact path="/st/cancel" element={<StripeCancelPage />} />
          <Route exact path="/orderdetails/:order_txn_id" element={<Layout><PrivateRoute><OrderDetails /></PrivateRoute></Layout>} />
          <Route exact path="/disclaimer" element={<Layout><Disclaimer /></Layout>} />
          <Route exact path="/privacy" element={<Layout><Privacy /></Layout>} />
          <Route exact path="/shipping-returns" element={<Layout><Shipping /></Layout>} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>

        {/* <Footer /> */}
        {/* </React.Fragment> */}
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
