import { useEffect } from "react";
import useCartStore from "../../../../zustand/useCardstore";
import { useNavigate } from "react-router-dom";

export const PayEXReturnPage = () => {
    const { clearCart, updateCardUID } = useCartStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        clearCart();
        updateCardUID("");
        setTimeout(() => {
            navigate("/")
        }, 1000);
    }, [])
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <p>Please wait we are processing your request...</p>
        </div>
    )
}

export default PayEXReturnPage;