import { useEffect, useState } from "react";
import * as api from "../../../../services/index";
import SuccessImage from "../../../../assets/images/success-mark.png";
import FailedImage from "../../../../assets/images/failed-mark.png";
import useCartStore from "../../../../zustand/useCardstore";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/logo.png";

const StripeReturnPage = () => {
    const [seconds, setSeconds] = useState(5);
    const [isActive, setIsActive] = useState(true);
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [txnId, setTxnId] = useState(0);
    const [txnAmount, setTxnAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [txnDate, setTxnDate] = useState("");
    const { clearCart, updateCardUID } = useCartStore((state) => state);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const sessionId = urlParams.get("session_id");

                let payload = {
                    session_id: sessionId
                };

                let response = await api.StripeReturnApi(payload);
                if (response?.data?.status === 200) {
                    setTxnId(response?.data?.txnId);
                    setTxnAmount(response?.data?.txnAmount);
                    setMsg(response?.data?.message);
                    setTxnDate(response?.data?.txnDate);
                    clearCart();
                    updateCardUID("");
                    navigate(`/success?txnId=${response?.data?.txnId}&txnAmount=${response?.data?.txnAmount}&txndate=${response?.data?.txnDate}&msg=${response?.data?.message}`)
                } else {
                    navigate("/failed")
                }
                setIsLoading(false);
            }
            catch (error) {
                navigate("/failed")
            }
        };

        fetchData();
    }, []);

    const stopTimer = () => {
        setIsActive(false);
    };

    useEffect(() => {
        let interval = null;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            navigate('/'); // Redirect to another page
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, navigate]);

    return (
        <div className="position-relative">
            <div className="py-4 d-flex justify-content-center" style={{ backgroundColor: "#3151b4" }}>
                <img width={"80px"} height={"80px"} src={logo} alt="Logo-icon" />
            </div>
            <div className="container d-flex flex-column justify-content-center align-items-center text-center" id="st">
                {/* {!isLoading ? (
                    status === "success" ? (
                        <section id="success" className="my-0 p-4 w-100">
                            <div className="row">
                                <div className="d-flex justify-content-start">
                                    <img className="" width="150px" height="150px" src={SuccessImage} alt="success-icon" />
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-start flex-column px-4">
                                    <h4 className="text-success text-start">Thanks for your order!</h4>
                                    <p className="text-start">An email with your login information is headed your way.</p>
                                    <p className="text-start">Sometimes this email can end up in your junk mail/SPAM folder or under “Promotions”. If you don’t see it within a few minutes, please search for the words noreply@leonardpersonality.com or email us and we’ll resend it. </p>
                                    <p className="text-start">So if you need help with anything, please don’t hesitate to email us at leonardpersonality@gmail.com</p>
                                    <p>Transaction Date: <strong>{txnDate}</strong></p>
                                    <p>Transaction Amount: <strong>RM {txnAmount}</strong></p>
                                    <p>Transaction ID: <strong>{txnId}</strong></p>
                                    <Link to="/" className="btn btn-outline-success border-0">Go to Home</Link>
                                    <p className="mt-4 text-muted">Redirecting to home page in {seconds} seconds...</p>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <section id="failed" className="my-5 p-4">
                            <div className="row">
                                <div className="d-flex mb-4 justify-content-start">
                                    <img className="" width="150px" height="150px" src={FailedImage} alt="success-icon" />
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-start flex-column px-4">
                                    <h4 className="text-danger text-start">Oops... Payment Failed!</h4>
                                    <p className="text-start">An email with your login information is headed your way.</p>
                                    <p className="text-start">Sometimes this email can end up in your junk mail/SPAM folder or under “Promotions”. If you don’t see it within a few minutes, please search for the words noreply@leonardpersonality.com or email us and we’ll resend it. </p>
                                    <p className="text-start">So if you need help with anything, please don’t hesitate to email us at leonardpersonality@gmail.com</p>
                                    <Link to="/" className="btn btn-outline-success border-0">Go to Home</Link>
                                    <p className="mt-4 text-muted">Redirecting to home page in {seconds} seconds...</p>
                                </div>
                            </div>
                        </section>
                    )
                ) : ( */}
                <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                    <div>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div>
                        <p className="text-muted">Please wait, we are processing your request...</p>
                    </div>
                </div>
                {/* )} */}
            </div>
        </div>
    );
};

export default StripeReturnPage;
