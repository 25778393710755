import React from 'react';
import "../css/static-about.css";
import img1 from "../assets/images/abt_img.png";
import img2 from "../assets/images/inner-abt-1.jpg";
import img3 from "../assets/images/inner-abt-2.jpg";
import img4 from "../assets/images/inner-abt-3.jpg";
import img5 from "../assets/images/inner-abt-4.jpg";
import img6 from "../assets/images/all_icon.png";

const About = () => {
    return (
        <div>
            <div className="headingBox">
                <div className="container">
                    <div className="mainHeading">
                        <article className="mainHeading__text">
                            <p className="mainHeading__preTitle">LPI</p>
                            <h2 className="mainHeading__title">About Us</h2>
                        </article>
                    </div>
                </div>
            </div>
            <div className="main-about tnb7" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="abut-text-img">
                                <img src={img1} className="img-fluid" alt="About" />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="abut-text"> 
                                <div className="big-heading">
                                    DR. LEONARD YONG
                                    <br />
                                    <span>(PhD; M.Ed; B.Sc.Hon)</span>
                                </div>
                                <p>
                                    Dr. Leonard Yong is an international consultant who has
                                    trained and consulted in many countries internationally. He is
                                    an Educational & Industrial Psychologist. Prior to his
                                    retirement as Professor, Universiti Malaya's Dept of
                                    Counseling & Educational Psychology, he has taught more than
                                    20 years in Universiti Malaya. He was also the pioneering
                                    Director of Universiti Malaya Centre for Continuing Education.
                                    Dr. Yong has gained international recognition for his research
                                    and consultancy work on creativity & emotional intelligence.
                                    Based on his pioneering work on the LEONARD Personality
                                    Inventory (LPI) & the LPI Software, and its use in helping
                                    individuals to develop their Emotional Intelligence (EQ) &
                                    creativity, Dr. Yong has consulted and conducted numerous
                                    training workshops on
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <p>
                                EQ and Creative Thinking for leaders in organizations such as
                                British Petroleum (BP), Microsoft Thailand, Cathay Pacific
                                Airlines, Thai Securities Exchange Commission, Readers Digest,
                                Reuters, Chularat Hospital Thailand, Rasa Saying Hotel, Pelangi
                                Hotel, Equatorial Hotel, Horwath International, Tan Chong
                                Motors, Maybank, Acer Technology, Motorola, Intel. Kian
                                Holdings, Public Packages Holdings, Unilever Thailand, Syngenta,
                                Carat Club, B Braun, Istana Hotel, Young Entrepreuneurs
                                Organization (YEO), Young Presidents Organizations(YPO),
                                Petronas, Kuwait Petroleum Company, Oman Petroleum Company and
                                Saudi Arabian Oil Company (ARAMCO).
                            </p>
                            <br />
                            <p>
                                Dr. Yong has addressed groups in many countries such as the
                                United States of America, Austria, Britain, Australia, France,
                                New Zealand, Germany, Italy, Russia, Japan, China, India, Korea,
                                Indonesia, Burma, Hong Kong, Singapore, Thailand and Papua New
                                Guinea. He has undertaken creativity & EQ research in the USA,
                                Australia, Austria, Germany, Italy, Japan and the United
                                Kingdom.
                            </p>
                            <br />
                            <p>
                                He was awarded the 1992 Fulbright-ACLS/MACEE Award to undertake
                                research on the creativity of Americans. He was awarded the 1993
                                Japan Foundation Research Fellowship Award to conduct research
                                on Japanese creativity. He was also awarded the Senior Research
                                Fellowship, European Studies Program 2000 (Bocconi University,
                                Italy) to conduct personality research in Italy, Germany and
                                Scotland.
                            </p>
                            <br />
                            <p>
                                Dr. Yong’s books include Creativity: A Study of Malaysian
                                Students, Pemikiran Kreatif and The LEONARD Personality
                                Inventory. His articles have appeared in journals such as
                                Perceptual & Motor Skills, The Journal of Creative Behavior and
                                Education Quarterly. He is a member of the American
                                Psychological Association and the British Psychological Society.
                            </p>
                            <br />
                            <p>
                                Presently he is the Adjunct Professor of Asia e University and
                                the Endowed Chair Professor of the Tunku Abdul Rahman University
                                College’s Leonard Personality Centre of Excellence.
                            </p>
                        </div>
                    </div>

                    <div className="inner-abut-text">
                        <div className="big-heading-center">
                            Applications Of The Leonard Personality Inventory
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="">
                                    <img
                                        src={img2}
                                        className="img-fluid"
                                        alt="Applications of LPI"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="inner-abut-text-right">
                                    <h5>The LPI will help individuals to:</h5>
                                    <ul>
                                        <li>
                                            <p>
                                                Gain self-awareness regarding their preferred behaviours
                                            </p>
                                        </li>
                                        <li>
                                            <p>Develop strategies to remove emotional blind spots</p>
                                        </li>
                                        <li>
                                            <p>Understand others’ behaviours and motivations</p>
                                        </li>
                                        <li>
                                            <p>Appreciate others better</p>
                                        </li>
                                        <li>
                                            <p>Develop better teamwork with others</p>
                                        </li>
                                    </ul>
                                    <br />
                                    <h5>The LPI can be used in the organization for:</h5>
                                    <ul>
                                        <li>
                                            <p>
                                                Short-listing candidates for a position requiring
                                                certain preferred behaviors.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Coaching candidates designated for certain promotion
                                                positions in which new people skills may be required.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Leadership and management skill development programs for
                                                middle and senior managers.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Developing effectiveness at the workplace for optimal
                                                performance. Innovative Teamwork and building a Learning
                                                Organizational.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Team Building where individual team members are profiled
                                                and their potential conflicts are mapped out.
                                                Intervention strategies can then be given so that team
                                                members can use them to improve intra-team and
                                                inter-team communication. Emotional blind spots of
                                                individual members can also be revealed in the feedback
                                                session to the individual.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-abut-text">
                        <div className="big-heading-center">
                            What is Emotional Intelligence?
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="inner-abut-text-right">
                                    <p>
                                        EQ is the ability to sense, understand and effectively apply
                                        the power and acumen of emotions as a source of human
                                        energy, information and influence. Emotional Intelligence
                                        (EQ) emerges not merely from intellectual rationalization,
                                        but from the workings of the human heart. EQ requires that
                                        we learn to acknowledge and value feelings in ourselves and
                                        others – that we appropriately respond to them, effectively
                                        applying the information and energy of emotions in our daily
                                        life and work. It is Emotional Intelligence that motivates
                                        us to pursue our unique potential and purpose, and activates
                                        our innermost values and aspirations, transforming them from
                                        things we only dream about into actual realities. It is our
                                        Emotional Intelligence that helps us be inspired, ignite our
                                        passions, discover our purpose, embrace change, build a
                                        sense of belonging and deepen the quality of our
                                        relationships with others. People with high EQ make better
                                        decisions, handle stress and conflicts better, and navigate
                                        social complexities more effectively.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="">
                                    <img
                                        src={img3}
                                        className="img-fluid"
                                        alt="Emotional Intelligence"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-abut-text">
                        <div className="big-heading-center">What is Creative Thinking?</div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="">
                                    <img
                                        src={img4}
                                        className="img-fluid"
                                        alt="Creative Thinking"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="inner-abut-text-right">
                                    <p>
                                        Creative Thinking is the ability to produce new ideas and
                                        new ways of seeing things. It is a structured thinking
                                        process that includes generating new ideas and putting them
                                        into action, so that they produce practical and relevant
                                        outcomes. People with high creative thinking skills are able
                                        to look at challenges from different angles and
                                        perspectives, and are open to change, innovation and
                                        improvement. They possess curiosity, the ability to see
                                        connections between seemingly unrelated things, and the
                                        skill to apply imagination and creativity to solve problems.
                                        Creative thinkers are not afraid to take risks, learn from
                                        their mistakes, and push boundaries to explore new
                                        possibilities and solutions. In today's fast-changing and
                                        competitive world, creative thinking is essential for
                                        success in all fields.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-abut-text">
                        <div className="big-heading-center"> 
                            EQ Success Factors for Individual & Corporate Transformation
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="inner-abut-text-right">
                                    <p>
                                        The high EQ individual demonstrates abilities to pursue
                                        goals with vision, perseverance and energy. Research has
                                        indicated that the following emotional competencies
                                        contribute significantly to predict success at work:
                                    </p>{" "}
                                    <br />
                                    <ul>
                                        <li>
                                            <p>Self-Awareness</p>
                                        </li>
                                        <li>
                                            <p>Emotional Resilience</p>
                                        </li>
                                        <li>
                                            <p>Decisiveness</p>
                                        </li>
                                        <li>
                                            <p>Interpersonal Sensitivity</p>
                                        </li>
                                        <li>
                                            <p>Influencing and Persuasion abilities</p>
                                        </li>
                                        <li>
                                            <p>Conscientiousness and Integrity</p>
                                        </li>
                                        <li>
                                            <p>Motivation</p>
                                        </li>
                                    </ul>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="">
                                    <img src={img5} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="dot-6">
                            <img className="img-fluid" src={img6} alt=""/>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="main-body-text">
                                    <div className="big-heading">
                                        {" "}
                                        The LEONARD Personality Inventory
                                    </div>
                                    <p>
                                        Based on a number of years of research into the personality
                                        traits, Dr. Leonard Yong has developed the LEONARD
                                        Personality Inventory (LPI). The LPI has been named in this
                                        way because it attempts to help us with Let's Explore our
                                        Openness, Neutral, Analytical, Relational and Decisive
                                        behavioural tendencies. The LEONARD Personality Inventory
                                        can identify a person's emotional orientation and locate a
                                        person's preferred behavioural orientation on any of the
                                        five behavioural dimensions, namely Openness, Neutral,
                                        Analytical, Relational and Decisive dimensions.{" "}
                                    </p>
                                    <p>
                                        The LEONARD Personality Inventory is based on research work
                                        by Dr. Leonard Yong in the last ten years and is modelled
                                        after a combination of both the four Greek temperaments and
                                        the Big Five Model. The approximate equivalent of the
                                        LEONARD Personality Inventory to these two models are as
                                        follows:
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="main-body-text table-bordered-scrool">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <h3>Greek Temperaments</h3>
                                                </th>
                                                <th scope="col">
                                                    <h3>LEONARD </h3>
                                                </th>
                                                <th scope="col">
                                                    <h3>Big Five Model</h3>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p>Openness</p>
                                                </td>
                                                <td>
                                                    <p>Openness</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Phlegmatic </td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Neutral{" "}
                                                </td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Agreeableness{" "}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Melancholic</td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Analytical
                                                </td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Conscientiousness
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Sanguine</td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Relational
                                                </td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Extraversion or
                                                    Surgency
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Choleric</td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Decisive
                                                </td>
                                                <td>
                                                    <i className="fa fa-caret-right"></i> Emotional stability
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>
                                    Results from the administrations of the LEONARD Inventory in
                                    Malaysia have indicated the validity and reliability of the
                                    Personality Inventory for the purpose of identifying the
                                    preferred behavioural styles of respondents. Intervention
                                    programs have been designed to assist individuals to enhance
                                    their emotional intelligence (intra-personal and
                                    inter-personal skills). This has been found to be especially
                                    useful in helping organizations, which have encouraged
                                    teamwork and innovative approaches to the workplace through
                                    harmonious and synergistic teamwork. Other uses include
                                    premarital and marital counselling.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="inventry-main mb-5">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="inventry-main-text">
                                    <div className="big-heading-center"> 
                                        Your LEONARD Personality Inventory
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Neutral</h5>
                                    <p>
                                        Good Listeners
                                        <br /> Like to live in harmony <br />
                                        Patient
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Data Oriented</h5>
                                    <p>
                                        Introvert <br />
                                        Cautious <br />
                                        Diligent <br />
                                        Perfectionistic
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Analytical</h5>
                                    <p>
                                        Cautious <br />
                                        Like to be precise <br />
                                        Can work alone
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>People Oriented</h5>
                                    <p>
                                        Warm personality <br />
                                        Shares feelings easily <br />
                                        Approachable
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Openness</h5>
                                    <p>
                                        Are creative <br />
                                        Like to try new things <br />
                                        Tend to be curious
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Task Oriented </h5>
                                    <p>
                                        Desire to be in control <br />
                                        Like to lead <br />
                                        Can solve problems well
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Relational</h5>
                                    <p>
                                        Full of life and excitement <br />
                                        Persuasive <br />
                                        Convincing
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Action Oriented</h5>
                                    <p>
                                        Extravert <br />
                                        Loves challenges <br />
                                        Makes friends easily <br />
                                        Inspires others
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="inventry">
                                    <h5>Decisive</h5>
                                    <p>
                                        Confident <br />
                                        Like to win <br />
                                        Bold
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h6>
                            Reference: Leonard Yong (1999). The LEONARD Personality Inventory.
                            Kuala Lumpur.
                        </h6>
                    </div>
                </div>

                <div className="dot-6">
                    <img src={img6} alt="" />
                </div>
            </div>

        </div>

    )
}

export default About