import React from 'react'
import { RiMapPinLine } from "react-icons/ri";
import { BsFiletypePdf } from "react-icons/bs";
import img1 from '../assets/images/inner-ser-1.jpg';
import img2 from '../assets/images/inner-ser-2.jpg';
import img3 from '../assets/images/inner-ser-3.jpg';
import img4 from '../assets/images/inner-ser-4.jpg';
import { Link } from 'react-router-dom';
import courseEESuccess from "../pdfs/courseEESuccess.pdf"
import Emotional_excellence_for_innovative_leadership from "../pdfs/Emotional_Excellence_for_Innovative_Leadership.pdf";
import Licenced_LPI_trainer_certification_programmer from "../pdfs/Licensed_LPI_Trainer_certification_program.pdf";
import LPI_brochure from "../pdfs/lpi_brochure.pdf";
import LPI_sample_report from "../pdfs/LPI_sample_report.pdf";

const Services = () => {

    const baseSiteUrl = process.env.REACT_APP_PHOTO_URL ?? "http://leonardshop.locale/uploads";

    return (
        <div>
            <div className="headingBox">
                <div className="container">
                    <div className="mainHeading">
                        <article className="mainHeading__text">
                            <p className="mainHeading__preTitle">Our</p>
                            <h2 className="mainHeading__title">Services</h2>
                        </article>
                    </div>
                </div>
            </div>
            <div className="sec-services">
                <div className="bg-white py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-4">
                                <div className="sec-header">
                                    <div className="section-icon">
                                        <RiMapPinLine />
                                    </div>
                                    <span className="sec-title">  Training Workshops </span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-8">
                                <div className="service-card">
                                    <div className='mb-4'>
                                        We cater to your various needs by offering training workshops for your organization. These workshops can be tailor-made to suit your organization's needs. Among our current topics are:
                                    </div>

                                    <div className="img-container">
                                        <img src={img1} alt="" />
                                    </div>

                                    <ul>
                                        <li><p className='p-0 m-0 d-flex'>Emotional Excellence for Innovative Leadership <Link to={Emotional_excellence_for_innovative_leadership} className='text-danger px-2' target='_blank'> <BsFiletypePdf /> Open</Link></p></li>
                                        <li><p className='p-0 m-0 d-flex'>Emotional Excellence for Success in Work and Life <Link to={courseEESuccess} className='text-danger px-2' target='_blank'> <BsFiletypePdf /> Open</Link></p></li>
                                        <li><p className='p-0 m-0 d-flex'>Licensed LPI Trainer Certification Programme  <Link to={Licenced_LPI_trainer_certification_programmer} className='text-danger px-2' target='_blank'><BsFiletypePdf /> Open</Link></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-4">
                                <div className="sec-header">
                                    <div className="section-icon">
                                        <RiMapPinLine />
                                    </div>
                                    <span className="sec-title">  Psychometric Testing </span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-8">
                                <div className="service-card">
                                    <div className='mb-4'>We conduct psychometric testing for:</div>
                                    <div className="img-container">
                                        <img src={img2} alt="" />
                                    </div>
                                    <ul>
                                        <li> Personality Profiling using the LEONARD Personality Inventory</li>
                                        <li>EQ Measure</li>
                                        <li>IQ Tests</li>
                                    </ul>

                                    <div className='mb-3'>Please contact us for further details regarding the psychometric testing available.</div>

                                    <div className='text-danger'>Download Brochure (*.pdf format)</div>
                                    <small>Instructions: To save the file in Internet Explorer, right-click and select "Save Target As.."</small>
                                    <ul>
                                        <li>
                                            <p className='p-0 m-0 d-flex'>LEONARD Personality Inventory Brochure <Link to={LPI_brochure} className='text-danger px-2' target='_blank'> <BsFiletypePdf /> Open </Link></p>
                                        </li>
                                        <li>
                                            <p className='p-0 m-0 d-flex'> Sample LEONARD Personality Inventory Report  <Link to={LPI_sample_report} className='text-danger px-2' target='_blank'> <BsFiletypePdf />Open</Link></p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-4">
                                <div className="sec-header">
                                    <div className="section-icon">
                                        <RiMapPinLine />
                                    </div>
                                    <span className="sec-title">Statistical Data Processing </span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-8">
                                <div className="service-card">
                                    <div className='mb-4'>We provide data analysis services for:</div>
                                    <div className="img-container">
                                        <img src={img3} alt="" />
                                    </div>
                                    <ul>
                                        <li>Undergraduate and graduate students in management, business, social science, and research methods courses.</li>
                                        <li>Students preparing dissertations and theses for degree, masters and PhD level</li>
                                    </ul>

                                    <div>Statistical services we offer for students:</div>
                                    <ul>
                                        <li>Identify research questions</li>
                                        <li>Formulate research hypotheses</li>
                                        <li>Determine suitable sample size Instrumentation</li>
                                        <li>Design the data collection process Data analysis planning</li>
                                        <li>Analyze the collected data</li>
                                    </ul>

                                    <div>Results for the analysis includes</div>
                                    <ul>
                                        <li>SPSS report (tables, graphs, charts)</li>
                                        <li>Written report of results in APA format</li>
                                    </ul>

                                    <div>Contact us for a detailed estimate, we offer the following packages:</div>
                                    <ul>
                                        <li>Basic Descriptive statistical analyses (e.g. frequencies, means, medians, mode)</li>
                                        <li>Basic Inferential statistical analyses (e.g. t-tests, ANOVA, chi-square, etc)</li>
                                        <li>Advanced inferential statistical analyses (e.g. factor analyses)</li>
                                        <li>Formulate research hypotheses, determine sample size, data collection planning and instrumentation design</li>
                                    </ul>

                                    <div className='mb-3'>
                                        Data to be provided for statistical analysis needs to be in Microsoft Excel or SPSS format. If data entry is required, we will advise the pricing based on the volume of data entry. Further interpretation of the results and literature review must be done by the candidate. Statistical analyses service is provided by our PhD-level consultants. We will provide email support and work with you to complete your project successfully.
                                    </div>

                                    <div>
                                        Email: <Link href="mailto:leonardpersonality@gmail.com">leonardpersonality@gmail.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-4">
                                <div className="sec-header">
                                    <div className="section-icon">
                                        <RiMapPinLine />
                                    </div>
                                    <span className="sec-title">  Publications & Trainers </span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-8">
                                <div className="service-card">
                                    <div className='mb-4'>LPI - Related Publications & List of Certified Trainers</div>
                                    <div className="img-container">
                                        <img src={img4} alt="" />
                                    </div>
                                    <ul>
                                        <li><Link href="/trainers">Click here to view</Link> a list of Licensed LPI Trainers</li>
                                        <li><Link href="/publications">Click here to view </Link> the publications involving the LEONARD Personality Inventory (LPI) and the Yong EQ Inventory.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services