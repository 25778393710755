import axios from "axios";
import http from "./http";

export const SignOutApi = async (payload) => {
    const data = await http.post("auth/signout", payload);
    return data;
}

export const getBooksData = async () => {
    const { data } = await http.get("home-page/products");
    return data;
};

export const signIn = async (payload) => {
    const data = await http.post("auth/signin", payload);
    return data;
}

export const signUp = async (payload) => {
    const data = await http.post("auth/signup", payload);
    return data;
}

export const AddProductToCart = async (payload) => {
    const data = await http.post('cart', payload);
    return data;
}

export const RemoveCartItem = async (payload) => {
    const data = await http.delete(`cart/items/${payload.cart_id}/${payload.product_id}`);
    return data;
}

export const ClearCart = async (key) => {
    const data = await http.delete(`cart/${key}`)
    return data;
}

export const UpdateAvatar = async (formdata) => {
    const data = await http.post("profile/update/avatar", formdata);
    return data;
}

export const UpdateProfile = async (payload) => {
    const data = await http.put('profile/update/detail', payload);
    return data;
}

export const UpdatePassword = async (payload) => {
    const data = await http.post("profile/update/password", payload);
    return data;
}

export const ApplyCoupon = async (payload) => {
    const data = await http.get(`coupon/apply?cart_uid=${payload?.cart_uid}&coupon_code=${payload.coupon_code}&shipping_charges=${payload?.shipping_charges}`);
    return data;
}

export const PlaceOrder = async (payload) => {
    const data = await http.post("order/place", payload);
    return data;
}

export const getProductDetails = async (slug) => {
    const data = await http.get(`product/${slug}`);
    return data;
}

export const getPaymentIntent = async (payload) => {
    try {
        const data = await http.post(`order/st/create-payment`, payload);
        return data;
    }
    catch (error) {
        throw error
    }
}

export const StripeReturnApi = async (payload) => {
    try {
        const data = await http.post('order/st/verify-payment', payload);
        return data
    }
    catch (error) {
        throw error
    }
}

export const getCustomerOrders = async (customer_id, page = 1, txnid = "", status = "") => {
    const data = await http.get(`order/list?customer_id=${customer_id}&page=${page}&txnid=${txnid}&status=${status}`);
    return data;
}

export const orderDetails = async (txnId) => {
    const data = await http.get(`order/detail/${txnId}`);
    return data;
}

export const BannerImages = async () => {
    const data = await http.get('home-page/sliders');
    return data;
}

export const ForgotPassword = async (payload) => {
    const data = await http.post("auth/password/forgot", payload);
    return data;
}

export const ResetPassword = async (payload) => {
    const data = await http.post("auth/password/reset", payload);
    return data;
}

export const GetCountries = async () => {
    const { data } = await axios.get("/data.json");
    return data;
}

export const LpiList = async (customer_id, page) => {
    const data = await http.get(`/profile/lpi/test-accounts?customer_id=${customer_id}&page=${page}`);
    return data;
}

export const getShippingCharges = async () => {
    const data = await http.get('/settings/shipping');
    return data;
}

export const updateUser = async () => {
    const data = await http.get('/profile/detail');
    return data;
}



