// components/PublicRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import createUser from "../zustand/createUser"

const PublicRoute = ({ children }) => {
    const { isAuthorized } = createUser((state) => state);
    return isAuthorized ? <Navigate to="/" /> : children;
};

export default PublicRoute;
