// components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import createUser from "../zustand/createUser"

const PrivateRoute = ({ children }) => {
    const { isAuthorized } = createUser((state) => state);
    return isAuthorized ? children : <Navigate to="/account/signin" />;
};

export default PrivateRoute;
