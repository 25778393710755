import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PasswordLink = () => {
    const [seconds, setSeconds] = useState(60)
    const navigate = useNavigate();
    useEffect(() => {
        let interval = null;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            navigate("/account/forgotpassword"); // Redirect to home page
        }
        return () => clearInterval(interval);
    }, [seconds]);

    return <>
        <div className="container vertical-margin" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <div className="row justify-content-md-center m-auto" id="fs">
                <div className="col-12 col-md-10 fs-card m-auto text-center">
                    <h4 className="text-center mb-4">Password Reset Link</h4>
                    <p>Password reset link has been send to your email successfully. Please check your email inbox or spam folder.</p>
                    <p className="text-primary">You can try again after {seconds} seconds.</p>
                </div>
            </div>
        </div>
    </>
}

export default PasswordLink;