import React from 'react';
import '../css/staticpage.css';
import trainerSvg from '../assets/images/trainers.svg';

const Trainers = () => {
    return (
        <div>
            <div className="headingBox">
                <div className="container">
                    <div className="mainHeading">
                        <article className="mainHeading__text">
                            <p className="mainHeading__preTitle">list of</p>
                            <h2 className="mainHeading__title">Licensed LPI trainers</h2>
                        </article>
                    </div>
                </div>
            </div>

            <div className="container my-5">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="st-box overflow-x-auto">
                            <table className='st-table'>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Certification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>LT217</td>
                                        <td>Aaron Tham </td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT107</td>
                                        <td> Abdul Rahman bin Ahmad Badayai</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT130</td>
                                        <td>Adhila Ismail</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT166</td>
                                        <td>Adibah binti M Saleh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT311</td>
                                        <td>Amy Ong Pei Hoon</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT141</td>
                                        <td>Andrea Sim Siok Hui</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT198</td>
                                        <td>Andrew Au</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT211</td>
                                        <td>Andy Cheong Hoon Wah</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT290</td>
                                        <td>Ang Cheng Aun</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT224</td>
                                        <td>Ang Seng Wong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT142</td>
                                        <td>Ang Yaw Feng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT118</td>
                                        <td>Annie Margaret a/p Sandela Raran</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT052</td>
                                        <td>Ariya Ratanapornsiri</td>
                                        <td>LPI Licensed Facilitator</td>
                                    </tr>
                                    <tr>
                                        <td>LT175</td>
                                        <td>Arul John Peter</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT313</td>
                                        <td>Azura Binti Zainal</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT289</td>
                                        <td>Balachandar A/L Ramakrishnan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT105</td>
                                        <td>Balasubramaniam a/l Somasundaram</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT315</td>
                                        <td>Beh Chong You</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT203</td>
                                        <td>Brahmmakumari a/p Balaraman</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT004</td>
                                        <td>Brenda Yong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT104</td>
                                        <td>Chai Ming Sing, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT143</td>
                                        <td>Cheah Phaik Kin, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT020</td>
                                        <td>Cheak Sook Kun</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT179</td>
                                        <td>Chee Choong Kooi, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT137</td>
                                        <td>Chee Kok Beng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT205</td>
                                        <td>Cheng Sau Keng, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT109</td>
                                        <td>Cheng Wei Fong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT165</td>
                                        <td>Chew Hock Kee</td>
                                        <td>Licensed LPI Psychotherapist</td>
                                    </tr>
                                    <tr>
                                        <td>LT112</td>
                                        <td>Chew Khoon Hee, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT213</td>
                                        <td>Chew May-Ann</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT018</td>
                                        <td>Chew Siew Wei</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT312</td>
                                        <td>Chia Meow Lin</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT283</td>
                                        <td>Chong Cheong Ping @ Chris</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT114</td>
                                        <td>Choong Hoon Guan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT308</td>
                                        <td>Choong Shiau Huai, Dr.</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT133</td>
                                        <td>Christine Velu</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT002</td>
                                        <td>Christopher Raj Anthony</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT282</td>
                                        <td>Crystal Tan Hooi Ching</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT272</td>
                                        <td>Daniel Ng Wee Jin</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT139</td>
                                        <td>David Ho</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT317</td>
                                        <td>David Lau Lee Hian</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT284</td>
                                        <td>Dinishvarkumar a/l Ananthkumar</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT145</td>
                                        <td>Diong Fong Wei</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT281</td>
                                        <td>Edwin Ooi Teik Chai</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT172</td>
                                        <td className="xl25">Eh Wen A/L Nen Di</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT223</td>
                                        <td>Ekkawit Matchawech</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT276</td>
                                        <td>Fairuz binti Omar</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT163</td>
                                        <td>Feiri Yenna</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT180</td>
                                        <td>Florence Kuek Chee Wee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT314</td>
                                        <td>Foo Ay Chiwn</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT146</td>
                                        <td>Foong Soon Seng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT021</td>
                                        <td>Franklin Morais</td>
                                        <td>LPI Licensed Psychotherapist</td>
                                    </tr>
                                    <tr>
                                        <td>LT186</td>
                                        <td>Gan Pei Lee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT140</td>
                                        <td>Gary De Witt</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT202</td>
                                        <td>Ghan Hsieh Han</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT181</td>
                                        <td>Gladys Koh Lee Shong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT147</td>
                                        <td>Glory Nancy Viapude</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT138</td>
                                        <td>Goh Hai Bee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT134</td>
                                        <td>Goh Lee Ying</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT270</td>
                                        <td>Goh Wai Meng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT197</td>
                                        <td>Grace Koh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT037</td>
                                        <td>Grace Shan</td>
                                        <td>LPI Licensed Facilitator</td>
                                    </tr>
                                    <tr>
                                        <td>LT101</td>
                                        <td> Harry Goh Keng Siong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT131</td>
                                        <td>Hiew Meng Hao</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT239</td>
                                        <td>Ho Khee Hoong (Daniel)</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT240</td>
                                        <td>Ho Khee Hoong (Daniel)</td>
                                        <td>Licensed LPI Trainer (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT304</td>
                                        <td>Hong Kok Pan</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT201</td>
                                        <td>How Pui Kuan, Cecilia</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT216</td>
                                        <td>Indrajeet Sen</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT278</td>
                                        <td>Jamalludin Bin Mantrang</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT210</td>
                                        <td>James Hoh Si-Tchuen</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT219</td>
                                        <td>Jane Chee Peck Wan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT229</td>
                                        <td>Jasmine Lai</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT204</td>
                                        <td>Jeffrey Chan Kok Wah</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT127</td>
                                        <td>Jessica Bun</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT182</td>
                                        <td>Joanna Tan Tjin Ai</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT148</td>
                                        <td>Joginder Kaur a/p Bhan Singh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT174</td>
                                        <td>Jonas Foo Kwan Min</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT178</td>
                                        <td>K.Rubini a/p Kulasingam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT149</td>
                                        <td>Kai Shuen Pheh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT232</td>
                                        <td>Kanimoli a/p Jeevanantham (Angelina)</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT183</td>
                                        <td>Karen Lee Siew Yen</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT108</td>
                                        <td> Karthiyani a/p Shanmugam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT236</td>
                                        <td>Karunagaran Pillay a/l Gurunathen</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT303</td>
                                        <td>Keh Hooi Chuen</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT102</td>
                                        <td> Kek Lay Wah</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT022</td>
                                        <td>Kelly Chong Kai Li</td>
                                        <td>LPI Licensed In-House Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT280</td>
                                        <td>Kent Sim</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT177</td>
                                        <td>Khartika a/p Chandrasekaran</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT113</td>
                                        <td> Khamurudin Mohd Noor, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT122</td>
                                        <td> Kok Jin Kuan, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT176</td>
                                        <td>Krishnan a/l Vengidasamy</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT106</td>
                                        <td> Lai Chooi Seong, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT016</td>
                                        <td>Law Hwee Theen</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT189</td>
                                        <td>Law Mei Yui</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT121</td>
                                        <td> Lee Mah Ngee, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT305</td>
                                        <td>Lee Phaik Ling</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT297</td>
                                        <td>Lee Pui Sum</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT125</td>
                                        <td>Lee Shealin</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT150</td>
                                        <td>Lee Wan Ying</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT128</td>
                                        <td>Lee Wei Shiong</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT003</td>
                                        <td>Leona Yong May Yan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT171</td>
                                        <td>Leong Chee Hoe</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT233</td>
                                        <td>Liew Chee Khuan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT017</td>
                                        <td>Liew Wai Yoon</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT307</td>
                                        <td>Lim Khai Yin</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT310</td>
                                        <td>Lim Poh Keng</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT199</td>
                                        <td>Lim Wee Ping</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT151</td>
                                        <td>Lim Yeong Yeong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT209</td>
                                        <td>Lim Yeu Chuen, Dr</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT200</td>
                                        <td>Lin Siew Fong, Dr</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT015</td>
                                        <td>Loh Nyuk Leung</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT152</td>
                                        <td>Loh Wan Lin</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT153</td>
                                        <td>Loo Siew Chee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT275</td>
                                        <td>Loo Tai Wai Ashley</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT271</td>
                                        <td>Loshene Ravindra Rajan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT296</td>
                                        <td>Lo Wei Chern</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT154</td>
                                        <td>Low Chee Seong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT214</td>
                                        <td>Low Wen Yan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT115</td>
                                        <td>Low Sew Kim</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT124</td>
                                        <td>Lum Jin Sun</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT103</td>
                                        <td>Lum Pui Yee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT129</td>
                                        <td>Lynette Chow Cheng Wei</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT185</td>
                                        <td>Maj (Rtd) Michael Chua RMAF</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT291</td>
                                        <td>Mala A/P Ramakrishnan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT155</td>
                                        <td>Manmeet Kaur Senthok Singh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT188</td>
                                        <td>Manoj Shah</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT062</td>
                                        <td>Mary Fatimah a/p A. Gordon Martin</td>
                                        <td>Certified LPI Life Coach</td>
                                    </tr>
                                    <tr>
                                        <td>LT300</td>
                                        <td>Melissa Lee Phooi Kuan</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT027</td>
                                        <td>M. Shanmugam</td>
                                        <td>Licensed LPI Life Coach</td>
                                    </tr>
                                    <tr>
                                        <td>LT028</td>
                                        <td>M. Shanmugam</td>
                                        <td>Licensed LPI Consultant</td>
                                    </tr>
                                    <tr>
                                        <td>LT294</td>
                                        <td>Mohamed Saeed Harib Alharmoul</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT245</td>
                                        <td>Nann Sandi Moon</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT220</td>
                                        <td>Navamani a/p Nagappon</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT225</td>
                                        <td>Navamani a/p Nagappon</td>
                                        <td>Licensed LPI Psychotherapist</td>
                                    </tr>
                                    <tr>
                                        <td>LT230</td>
                                        <td>Nay Lynn Phyo</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT227</td>
                                        <td>Ng Foo Yek</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT164</td>
                                        <td>Ng Yoke Ying</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT191</td>
                                        <td>Ng Zhi Kuan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT306</td>
                                        <td>Nur Baiti Binti Ismail Shauki</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT173</td>
                                        <td>Norhasniza Binti Abdul Hamid</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT169</td>
                                        <td>Nurul Izwazi Binti Mohd Nor</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT285</td>
                                        <td>Nurfatima Zahra Binti Abu Bakar</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT226</td>
                                        <td> Ong Bee Teng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT243</td>
                                        <td>Ong Mor Yang</td>
                                        <td>Licensed LPI Trainer (limited to practice in TARUC)</td>
                                    </tr>
                                    <tr>
                                        <td>LT100</td>
                                        <td> Oo Pou San, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT299</td>
                                        <td>Ow Mun Waei</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT136</td>
                                        <td>Patrick Cheng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT001</td>
                                        <td>Paul Davidson</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT019</td>
                                        <td>Phang Chan Yung</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT298</td>
                                        <td>Phuah Kit Teng</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT156</td>
                                        <td>Pong Kok Shiong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT144</td>
                                        <td>Priscilla Moses, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT292</td>
                                        <td>Pushpa Srinivas</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT244</td>
                                        <td>Quek Ser Pin</td>
                                        <td>Licensed LPI Trainer (limited to practice in TARUC)</td>
                                    </tr>
                                    <tr>
                                        <td>LT215</td>
                                        <td>Rajeev Shrivastava</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT157</td>
                                        <td>Raphael Thoo Yi Xian</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT293</td>
                                        <td>Rashed Saeed Yousuf Abdulrahim</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT187</td>
                                        <td>Raymond Marsden</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT195</td>
                                        <td>Rich Fozi</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT286</td>
                                        <td>Rita Chee Lean Phaik</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT123</td>
                                        <td>Rithy Sim</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT218</td>
                                        <td>Roland Low</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT288</td>
                                        <td>Rohini Kamalanathan Van Hoboken, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT132</td>
                                        <td>Ronald Chandra Simon</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT287</td>
                                        <td>S. Indrani A/P Shanmugam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT192</td>
                                        <td>Samuel Ramesh</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT158</td>
                                        <td>Sanggari a/p Krishnan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT279</td>
                                        <td>Santhi a/p Subramaniam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT238</td>
                                        <td>Sashikala Sunmugam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT242</td>
                                        <td>Sathiaseelan Thangavelu</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT234</td>
                                        <td>Saundrajuli George</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT235</td>
                                        <td>Saw David</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT168</td>
                                        <td>Seng Htoi</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT206</td>
                                        <td>Seema Agarwal</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT212</td>
                                        <td>Selvi Valsala a/p R. Shanmuganathan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT159</td>
                                        <td>Sheau Wen Ong</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT222</td>
                                        <td>Sheila Cheng</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT120</td>
                                        <td> Siah Poh Chua, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT135</td>
                                        <td>Siew Lai Heong, Germaine</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT116</td>
                                        <td>Sim Chzia Poaw</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT194</td>
                                        <td>Sorrapakksorn Chatrakamollathas, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT231</td>
                                        <td>Su Myat Mon @ Starry</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT295</td>
                                        <td>Tan Khoon Lee, William</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT237</td>
                                        <td>Tan Li Peng</td>
                                        <td>Licensed LPI Trainer (limited to practice in TARUC)</td>
                                    </tr>
                                    <tr>
                                        <td>LT111</td>
                                        <td>Tan Siok Inn</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT274</td>
                                        <td>Tan Swee Bee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT014</td>
                                        <td>Tee Sock Sing</td>
                                        <td>LPI Licensed Psychotherapist (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT160</td>
                                        <td>Teh Su Yen</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT316</td>
                                        <td>Teng Howe Cheng</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT221</td>
                                        <td>Teo Soon Beng (Simon)</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT167</td>
                                        <td>Thang Lian Kap</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT170</td>
                                        <td>Thirunesan Subramaniam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT301</td>
                                        <td>Ting Hie Choon</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT161</td>
                                        <td>Ting Ying Tan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT241</td>
                                        <td>T’ng Soo Ting (Grace)</td>
                                        <td>Licensed LPI Trainer (limited to practice in UTAR)</td>
                                    </tr>
                                    <tr>
                                        <td>LT302</td>
                                        <td>Toh Guat Guan, Assoc Prof Dr </td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT190</td>
                                        <td>Victor Wong Chen Nam</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT057</td>
                                        <td>Vigneswaran Kannan</td>
                                        <td>Certified LPI Life Coach</td>
                                    </tr>
                                    <tr>
                                        <td>LT228</td>
                                        <td>Vigneswaran Kannan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT110</td>
                                        <td>Wee Chu Kok</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT162</td>
                                        <td>William Hoi Wei Yuan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT277</td>
                                        <td>Wilson Cheah Hui Pin</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT119</td>
                                        <td>Wirawahida Kamarulzaman</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT126</td>
                                        <td>Wong Jia Sien</td>
                                        <td>Certified LPI Assessor</td>
                                    </tr>
                                    <tr>
                                        <td>LT184</td>
                                        <td>Wong Kah Ong Benny</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT309</td>
                                        <td>Wong Ngik Lin</td>
                                        <td>Licensed LPI Trainer (limited to practice in TAR UMT)</td>
                                    </tr>
                                    <tr>
                                        <td>LT273</td>
                                        <td>Wong Tik Wah</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT196</td>
                                        <td>Wong Moi Lee</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT193</td>
                                        <td>Worathanat Tanyahaan</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT207</td>
                                        <td>Yap Ee Na</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT208</td>
                                        <td>Yap Siew Ting, Dr.</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>
                                    <tr>
                                        <td>LT117</td>
                                        <td>Zahari bin Abd Malek</td>
                                        <td>Licensed LPI Trainer</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Trainers