import React, { useEffect, useRef } from "react";
import {
    FaShoppingCart,
} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../css/sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import useCartStore from "../../zustand/useCardstore";
import { FiChevronRight } from "react-icons/fi";
import CartItem from "./CartItem";
import { useMutation } from "@tanstack/react-query";
import * as api from "../../services/index";
import toast from "react-hot-toast";

const Sidebar = ({ isOpen, handleSidebarToggle, cartItems }) => {
    const { addToCart, cartuid } = useCartStore((state) => state);
    const navigate = useNavigate()
    const sidebarRef = useRef(null);
    const { mutate } = useMutation({
        mutationFn: (key) => api.ClearCart(key),
        onSuccess: (data) => {
            addToCart(data?.data?.data);
            toast.success(data?.data?.message)
        },
        onError: (error) => {
            console.log("error:", error);
        }
    })

    const cart = useCartStore((state) => state.cart);
    const totalPrice = cart?.items?.reduce(
        (total, item) => total + item?.product?.product_price * item.quantity,
        0
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                handleSidebarToggle();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, handleSidebarToggle]);

    const handleClearCart = () => {
        mutate(cartuid);
        handleSidebarToggle();
    }

    return (
        <div className={`cart-sidebar ${isOpen ? "open" : ""}`}>
            <div className="sidebar-header">
                <span
                    className="sidebar-title d-flex "
                    to="#"
                    onClick={handleSidebarToggle}
                >
                    <FiChevronRight size={19} className="arrow-icon" />
                    <h5 className="cart-title"> Your Cart</h5>
                </span>
                <div>
                    <span className="text-danger" style={{ cursor: "pointer" }} onClick={() => handleClearCart()}>
                        <MdDelete color="#dc3545" size={20} />
                    </span>
                </div>
            </div>
            <div className="sidebar-content">
                {cart?.items?.length === 0 ? (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <FaShoppingCart size={25} />
                        <p className="">Your cart is empty.</p>
                    </div>
                ) : (
                    <div className="cart-items-list">
                        {cart?.items?.map((item, index) => {
                            return <CartItem item={item} key={index} />
                        })}
                    </div>
                )}
            </div>
            <div className="sidebar-first-footer">
                <div>Total Price:</div>
                <div className="cart-title">RM {totalPrice?.toFixed(2)}</div>
            </div>
            <div className="sidebar-footer">
                <Link onClick={() => {
                    if (cart?.items?.length > 0) {
                        handleSidebarToggle()
                    }
                }} to={cart?.items?.length > 0 ? `/checkout` : "#"}>
                    <button className="checkout-btn">Checkout</button>
                </Link>
            </div>
        </div>
    );
};

export default Sidebar